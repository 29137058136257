import { View } from 'react-native';
import React from 'react';
import { addSeconds } from 'date-fns';
import { useI18n } from '../../context/I18nContext';
import { useMapViewFilter } from '../../context/MapViewFilterContext';
import { useAuth } from '../../context/AuthContext';
import { CARD_DATE_FMT_OPTS } from './sharedCardConfig';
import GradientButton from '../../components/GradientButton';
import { capitalizeFirstLetter } from '../../utils/helpers';
import BookingCardDurationSelector from './BookingCardDurationSelector';
import { useNowMinutes } from '../../hooks/useNow';
import Spacer from '../../components/Spacer';

export const UnauthenticatedCardContent = ({
  spaceTimezone,
  minBookingMins,
  maxBookingMins,
}: {
  spaceTimezone: string | undefined | null;
  minBookingMins: number | undefined;
  maxBookingMins: number | undefined;
}) => {
  const { I18n, formatDateRange, formatRelative, formatDuration } = useI18n();

  const { logoutAsGuest } = useAuth();
  const now = useNowMinutes();
  const { filter, onChange } = useMapViewFilter();
  const startEnd = formatDateRange(
    filter.date,
    addSeconds(filter.date, filter.durationInSecond),
    'time',
    spaceTimezone,
  );

  const isUnAvailable =
    minBookingMins !== undefined &&
    maxBookingMins !== undefined &&
    (filter.durationInSecond / 60 < minBookingMins * 60 || filter.durationInSecond > maxBookingMins * 60);

  return (
    <>
      <BookingCardDurationSelector
        pickerText={formatDuration(filter.durationInSecond, 'seconds')}
        pickerMin={minBookingMins}
        pickerMax={maxBookingMins}
        pickerValue={filter.durationInSecond / 60}
        pickerStep={60}
        onValueChange={(newValue) =>
          onChange({
            ...filter,
            durationInSecond: newValue * 60,
          })
        }
        caption={`${capitalizeFirstLetter(
          formatRelative(filter.date, now, spaceTimezone, CARD_DATE_FMT_OPTS),
        )}\n${startEnd}`}
        mainText={
          isUnAvailable ? I18n.t('mapview.podInfoCard.unavailableTitle') : I18n.t('mapview.podInfoCard.available')
        }
      />
      <Spacer />
      <View style={{ marginTop: 10 }}>
        <GradientButton
          style={{ marginTop: 20 }}
          onPress={() => {
            logoutAsGuest();
          }}
        >
          {I18n.t('mapview.podInfoCard.loginToUse')}
        </GradientButton>
      </View>
    </>
  );
};
