import { AppsyncData } from '../apis/appsyncHelper';
import { Pois, Reservation, ReservationStatus } from './appsync-types';

export type Pod = Pois;

export type PricingInfo = AppsyncData<'estimatePriceAndHoldAmount'>['estimatePriceAndHoldAmount'];

export type CompoundReservation = {
  initialId: string;
  from: Date;
  to: Date;
  status: ReservationStatus;
  spaceId: string;
  spaceInfo: Pod;
  timeZone: string | undefined | null;
  originals: Reservation[];
};

export enum PaymentMethodOption {
  CARD = 'CARD',
  GOOGLEPAY = 'GOOGLEPAY',
}

export enum ResourceSignalStrength {
  STRONG = 0,
  MODERATE = 1,
  WEAK = 2,
}
