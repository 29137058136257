import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { CARD_DATE_FMT_OPTS } from './sharedCardConfig';
import { useI18n } from '../../context/I18nContext';
import GradientButton from '../../components/GradientButton';
import { screenNames } from '../../navigators/screenNames';
import { AppDrawerNavigationProp } from '../../components/DrawerMenuContent';
import { CompoundReservation } from '../../types/misc.types';
import BookingCardDurationSelector from './BookingCardDurationSelector';
import { useNowMinutes } from '../../hooks/useNow';
import { useMapViewFilter } from '../../context/MapViewFilterContext';
import { capitalizeFirstLetter } from '../../utils/helpers';
import Spacer from '../../components/Spacer';

type Props = {
  reservation: CompoundReservation | undefined;
  minBookingMins: number | undefined;
  maxBookingMins: number | undefined;
};

export const BookedNotNowCardContent = ({ reservation, minBookingMins, maxBookingMins }: Props) => {
  const { I18n, formatRelative, formatDateRange, formatDuration } = useI18n();
  const navigation = useNavigation<AppDrawerNavigationProp>();
  const now = useNowMinutes();
  const { filter, onChange } = useMapViewFilter();
  const caption = I18n.t('mapview.podInfoCard.bookedNotNowTitle');
  const dateStr = capitalizeFirstLetter(
    formatRelative(new Date(reservation?.from ?? now), now, reservation?.timeZone, CARD_DATE_FMT_OPTS),
  );
  const startEnd = reservation ? formatDateRange(reservation.from, reservation.to, 'time', reservation.timeZone) : '';
  return (
    <>
      <BookingCardDurationSelector
        pickerText={formatDuration(filter.durationInSecond, 'seconds')}
        pickerMin={minBookingMins}
        pickerMax={maxBookingMins}
        pickerValue={filter.durationInSecond / 60}
        pickerStep={60}
        onValueChange={(newValue) =>
          onChange({
            ...filter,
            durationInSecond: newValue * 60,
          })
        }
        caption={caption}
        details={`${dateStr}\n${startEnd}`}
      />
      <Spacer />

      <GradientButton
        onPress={() => {
          navigation.navigate('map', {
            screen: screenNames.BookingDetail,
            params: reservation?.initialId
              ? {
                  bookingId: reservation.initialId,
                }
              : undefined,
          });
        }}
      >
        {I18n.t('mapview.podInfoCard.viewBooking')}
      </GradientButton>
    </>
  );
};
