import { createAxiosInstance, TokenType } from '../utils/apiInstanceFactory';
import podExport from '../../pod-exports.json';
import { ReservationStatus, SupportLanguage, UserRole } from '../types/appsync-types';

const axiosInstance = createAxiosInstance({}, TokenType.COGNITO);

type Language = string;

export type PushNotificationMessage = {
  title: Record<Language, string>;
  body: Record<Language, string>;
  timeToSend: string;
  priority: 'normal';
  receivers: string[];
  action: 'OPEN_APP';
  data: Record<string, string>;
};

export type VouchersResponse = any; // TODO
export type Profile = any; // TODO
export type PromoInviteCodeResponse = any; // TODO
export type CaptureDeposit = any; // TODO

export type Reservation = {
  paymentAmountInCents: number;
  depositAmountInCents: number;
  paymentId?: string | null;
  currency: string;
  billingType: string;
  reservedDay: string;
  status: ReservationStatus;
  maximumCharge: string;
  language: string;
  spaceId: string;
  buildingId: string;
  from: string;
  userId: string;
  receiptUrl?: string | null;
  captureDepositAmountInCents?: number | null;
  endat: string;
  id: string;
  chargeRates: ChargeRate[];
  stripeCustomerId?: string | null;
  refundId?: string | null;
  createdAt: string;
  userPhone: string;
  credit: number;
  updatedAt: string;
  poisId: string;
  minimumBookingMinutes: number;
  to: string;
  type: 'NEW' | 'EXTENDED';
};

export type ChargeRate = {
  min: number;
  max?: number;
  interval: number;
  price: number;
};

export function sendNewsNotification(
  title: string,
  body: string,
  url: string,
  language: SupportLanguage,
  timeToSend: Date,
  recipients: 'ALL' | string[],
) {
  return sendPushNotification({
    title: {
      [language]: title,
    },
    body: {
      [language]: body,
    },
    timeToSend: timeToSend.toISOString(),
    priority: 'normal',
    action: 'OPEN_APP',
    receivers: recipients === 'ALL' ? ['ALL'] : recipients,
    data: {
      url,
    },
  });
}

export function sendPushNotification(msg: PushNotificationMessage) {
  return axiosInstance.post(podExport.adminEndpoint + '/notifications/create', msg);
}

export function userResetFirstTimeLoginFlag(number: string): Promise<Profile> {
  return axiosInstance.put(podExport.adminEndpoint + '/user/firstTimeLogin', {
    phoneNumber: number,
  });
}

export function userSuspend(number: string, suspend: boolean): Promise<Profile> {
  return axiosInstance.put(podExport.adminEndpoint + '/user/suspend', {
    phoneNumber: number,
    suspend,
  });
}

export function userSetRole(phoneNumber: string, userRole: UserRole): Promise<Profile> {
  return axiosInstance.put(podExport.adminEndpoint + '/user/role', {
    userPhone: phoneNumber,
    userRole,
  });
}

export function vouchersCreate(
  visibleSourceName: string,
  internalBatchId: string,
  currency: string,
  valueCents: number,
  count: number,
  expiryDate: string,
): Promise<VouchersResponse> {
  const payload = {
    sourceName: visibleSourceName,
    internalNote: internalBatchId,
    currency,
    value: valueCents,
    amountOfVoucher: count,
    expiryDate,
  };
  return axiosInstance.post(podExport.adminEndpoint + '/credit/oneTimeVouchers', payload);
}

export function vouchersEnableByBatchId(internalBatchId: string, enable: boolean): Promise<VouchersResponse> {
  const payload = {
    internalNote: internalBatchId,
    enabled: enable,
  };
  return axiosInstance.put(podExport.adminEndpoint + '/credit/oneTimeVouchers', payload);
}

export function vouchersEnableByRange(from: number, to: number, enable: boolean): Promise<VouchersResponse> {
  const payload = {
    from,
    to,
    enabled: enable,
  };
  return axiosInstance.put(podExport.adminEndpoint + '/credit/oneTimeVouchers', payload);
}

export function vouchersGetByBatchId(internalBatchId: string): Promise<VouchersResponse> {
  const payload = {
    internalNote: internalBatchId,
  };
  return axiosInstance.get(podExport.adminEndpoint + '/credit/oneTimeVouchers', {
    params: payload,
  });
}

export function vouchersGetByRange(from: number, to: number): Promise<VouchersResponse> {
  const payload = {
    from,
    to,
  };
  return axiosInstance.get(podExport.adminEndpoint + '/credit/oneTimeVouchers', {
    params: payload,
  });
}

export function inviteCodeGet(code: string): Promise<PromoInviteCodeResponse> {
  const payload = { code };
  return axiosInstance.get(podExport.adminEndpoint + '/credit/promoCode', {
    params: payload,
  });
}

export function inviteCodeCreate(
  code: string,
  visibleSourceName: string,
  expiryDate: string,
  internalNote: string,
  currency: string,
  valueCents: number,
): Promise<PromoInviteCodeResponse> {
  const payload = {
    code,
    sourceName: visibleSourceName,
    expiryDate,
    internalNote,
    currency,
    value: valueCents,
  };
  return axiosInstance.post(podExport.adminEndpoint + '/credit/promoCode', payload);
}

export function inviteCodeEnable(code: string, enable: boolean): Promise<PromoInviteCodeResponse> {
  const payload = {
    code,
    enabled: enable,
  };
  return axiosInstance.put(podExport.adminEndpoint + '/credit/promoCode', payload);
}

export function userDelete(phoneNumber: string): Promise<Profile> {
  return axiosInstance.put(podExport.adminEndpoint + '/user/delete', { phoneNumber });
}

export function captureDeposit(reservationId: string, captureDepositAmountInCents: number): Promise<CaptureDeposit> {
  const payload = {
    reservationId,
    captureDepositAmountInCents,
  };
  return axiosInstance.post(podExport.adminEndpoint + '/refundRequiredReservations/capture', payload);
}

export function queryReservation(id: string): Promise<{ reservation: Reservation }> {
  return axiosInstance.get(`${podExport.adminEndpoint}/reservations/${id}`);
}

export function getReservationsByPhonenumber(
  userPhone: string,
  { from, to }: { from: string; to: string },
): Promise<{
  reservations: Reservation[];
}> {
  const queryParams = {
    userPhone,
    from,
    to,
  };
  return axiosInstance.get(podExport.adminEndpoint + '/reservationsByUserPhone', {
    params: queryParams,
  });
}

export function fullRefundReservation(reservationId: string): Promise<{ status: 'ok' }> {
  const payload = {
    reservationId,
  };
  return axiosInstance.post(podExport.adminEndpoint + '/fullRefundReservation', payload);
}
