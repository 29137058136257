import React, { useEffect } from 'react';
import GradientButton, { GradientButtonProps } from './GradientButton';
import { useI18n } from '../context/I18nContext';
import { Resource } from '../types/appsync-types';
import { SelectResourceModal } from './modal/SelectResourceModal';
import { useBitwards } from '../bitwards/BitwardsContext';
import { useModal } from '../context/ModalContext';
import { ResourceLockButton } from './ResourceLockButton';
import { rssiToSignalStrength } from '../utils/rssiToSignalStrength';

export type UnlockButtonProps = GradientButtonProps & { resources: (Resource | undefined | null)[] | undefined | null };

export function UnlockButton({ onPress, resources, disabled, loading, ...other }: UnlockButtonProps) {
  const { I18n } = useI18n();
  const { closeModal, openModal } = useModal();
  const { accessResource, loading: bitwardsLoading, visibleResources } = useBitwards();

  // TODO: Fix modal staying open after reservation ends when it was opened from BookedCardContent
  useEffect(() => {
    if (disabled) {
      closeModal();
    }
  }, [disabled, closeModal]);

  if (!resources) {
    return (
      <GradientButton icon={'lock'} disabled>
        {I18n.t('bookingDetail.unlock')}
      </GradientButton>
    );
  }

  if (resources?.length < 2) {
    const visibleResource = visibleResources.find((resource) => resource.id === resources[0]?.resourceId);
    return (
      <ResourceLockButton
        resourceId={resources[0]?.resourceId}
        signalStrength={visibleResource?.rssi !== undefined ? rssiToSignalStrength(visibleResource.rssi) : undefined}
        disabled={disabled}
        loading={loading}
        accessResource={accessResource}
        onPress={onPress}
        {...other}
      >
        {I18n.t('bookingDetail.unlock')}
      </ResourceLockButton>
    );
  }

  return (
    <>
      <GradientButton
        icon={'lock'}
        disabled={disabled}
        loading={loading}
        onPress={() =>
          openModal({
            content: (
              <SelectResourceModal
                resources={resources}
                visibleResources={visibleResources.map((resource) => ({
                  id: resource.id,
                  signalStrength: resource?.rssi !== undefined ? rssiToSignalStrength(resource.rssi) : undefined,
                }))}
                disabled={disabled}
                loading={loading || bitwardsLoading}
                accessResource={accessResource}
                onPress={onPress}
                onDismiss={() => closeModal()}
              />
            ),
          })
        }
      >
        {I18n.t('bookingDetail.unlock')}
      </GradientButton>
    </>
  );
}
