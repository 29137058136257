import { Button, useTheme } from 'react-native-paper';
import Constants from 'expo-constants';
import React from 'react';
import { View } from 'react-native';
import { differenceInMinutes } from 'date-fns';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useI18n } from '../../context/I18nContext';
import Typography from '../../components/Typography';
import { styles } from './sharedCardConfig';
import { UnlockButton } from '../../components/UnlockButton';
import { screenNames } from '../../navigators/screenNames';
import { flattenDict } from '../../utils/helpers';
import { useNowMinutes } from '../../hooks/useNow';
import { CompoundReservation } from '../../types/misc.types';
import { MapStackRouteParams } from '../../navigators/MapStackNavigator';
import { useMixpanel } from '../../mixpanel/MixpanelContext';
import { ReservationStatus } from '../../types/appsync-types';
import { GetFromStoreButton } from '../../components/GetFromStoreButton';

type Props = {
  ongoingReservation: CompoundReservation;
};

type navprops = StackNavigationProp<MapStackRouteParams, 'MapView'>;

export const BookedCardContent = ({ ongoingReservation }: Props) => {
  const { colors } = useTheme();
  const { I18n, formatDuration } = useI18n();
  const mp = useMixpanel();
  const now = useNowMinutes();
  const navigation = useNavigation<navprops>();

  const minutesDiff = differenceInMinutes(new Date(ongoingReservation?.to!), now);

  return (
    <>
      <View style={styles.scheduleSection}>
        <View style={{ flex: 1 }}>
          <Typography variant={'caption'} color={colors.onBackground}>
            {I18n.t('mapview.podInfoCard.bookedTitle')}
          </Typography>
          <Typography variant={'h4'} color={colors.onBackground}>
            {/* This check is for the case when reservation is awaiting payment and timer still runs */}
            {minutesDiff <= 1 ? `< ${formatDuration(1, 'minutes')}` : formatDuration(minutesDiff, 'minutes')}
          </Typography>
        </View>
      </View>

      {Constants.manifest?.extra?.disableBitwards ? (
        <GetFromStoreButton
          style={{ marginTop: 20 }}
          onUnknownStore={() => {
            navigation.navigate('ChooseStoreDialog');
          }}
        >
          {I18n.t('storeDialog.installToUnlockButton')}
        </GetFromStoreButton>
      ) : (
        <UnlockButton
          resources={ongoingReservation?.spaceInfo?.resources}
          style={{ marginTop: 20 }}
          onPress={() => {
            mp?.track('Unlock attempt from MapView booked card', flattenDict('space', ongoingReservation?.spaceInfo));
          }}
        >
          {I18n.t('mapview.podInfoCard.unlock')}
        </UnlockButton>
      )}
      <View style={styles.cardAction}>
        <Button
          labelStyle={{
            color: ongoingReservation?.status === ReservationStatus.COMPLETED ? colors.onSurface : colors.onBackground,
          }}
          disabled={ongoingReservation?.status === ReservationStatus.COMPLETED}
          onPress={() => {
            navigation.navigate(screenNames.ExtendReservationDialog, {
              compoundReservation: ongoingReservation,
            });
          }}
        >
          {I18n.t('mapview.podInfoCard.extend')}
        </Button>
        <Button
          disabled={ongoingReservation?.status === ReservationStatus.COMPLETED}
          labelStyle={{
            color: ongoingReservation?.status === ReservationStatus.COMPLETED ? colors.onSurface : colors.onBackground,
          }}
          onPress={() => {
            navigation.navigate('EndReservationDialog', { reservation: ongoingReservation });
          }}
        >
          {I18n.t('mapview.podInfoCard.endNow')}
        </Button>
      </View>
    </>
  );
};
