import { QueryClient, useQuery, useQueryClient } from 'react-query';
import { isBefore, isWithinInterval, sub, add, areIntervalsOverlapping, isAfter } from 'date-fns';
import { useMemo } from 'react';
import * as Sentry from '../components/sentry/sentry';
import { CompoundReservation } from '../types/misc.types';
import { getBuildingsWithinRadius, useGetSpaceScheduleInfo } from './mapApis';
import { useMyProfileQuery } from './appsyncApis';
import useSubscription from '../hooks/useSubscription';
import { useNowMinutes } from '../hooks/useNow';
import { useGetSpaceInfoWithPricing, useGetRecentCreditBalanceHistory } from '../payment/paymentApis';
import {
  MutationCancelAReservationArgs,
  MutationEndAReservationArgs,
  MutationExtendAReservationArgs,
  MutationMakeAReservationArgs,
  QueryGetMyReservationsArgs,
  ReservationStatus,
  Subscription,
  SubscriptionOnUpdateBuildingBookingStatusArgs,
  SubscriptionOnUpdateReservationArgs,
  TimeRangeInput,
} from '../types/appsync-types';
import { appsyncGraphql } from './appsyncHelper';
import { safeJsonStringify } from '../utils/helpers';

export const MAX_ADVANCE_RESERVE_DAYS = 364;

function safeAreIntervalsOverlapping(intervalLeft: Interval, intervalRight: Interval) {
  try {
    return areIntervalsOverlapping(intervalLeft, intervalRight);
  } catch {
    return true;
  }
}

type Reservation = CompoundReservation['originals'][number];

const MAX_RETRY = 3;

getMyReservations.id = 'getMyReservations';

type OnUpdateReservationSubscription = Pick<Subscription, 'onUpdateReservation'>;
type OnUpdateBuildingBookingStatusSubscription = Pick<Subscription, 'onUpdateBuildingBookingStatus'>;

function getMyReservations(variable: QueryGetMyReservationsArgs) {
  const query = /* GraphQL */ `
    query GetMyReservations($input: TimeRangeInput) {
      getMyReservations(input: $input) {
        id
        spaceId
        status
        from
        to
        userId
        receiptUrl
        paymentId
        paymentSecret
        paymentAmountInCents
        depositAmountInCents
        prevReservationId
        building {
          timezone
        }
        card {
          id
          last4
          brand
        }
        space {
          id
          lat
          lon
          currency
          buildingRef
          depositAmountInCents
          building {
            id
            lat
            lon
            buildingRef
            title
            websiteUrl
            imageUrl
            address
            timezone
          }
          localRef
          layerIndex
          title
          type
          resources {
            id
            title
            spaceId
            resourceType
            resourceId
          }
          metadata {
            imageUrl
            maxCapacity
          }
        }
      }
    }
  `;
  return appsyncGraphql<'getMyReservations'>(query, variable);
}

function useGetMyPastReservationQuery() {
  return useQuery(getMyReservations.id, () => {
    const now = new Date();
    return getMyReservations({
      input: {
        // TODO: Right now we are hard-coding to fetch last 1 year reservations
        // and next 1 year (MAX_ADVANCE_RESERVE_DAYS)
        from: sub(now, { months: 12 }).toISOString(),
        to: add(now, { days: MAX_ADVANCE_RESERVE_DAYS }).toISOString(),
      },
    });
  });
}

function combineStatus(a: ReservationStatus, b: ReservationStatus): ReservationStatus {
  if ([a, b].includes(ReservationStatus.PAYMENT_REQUIRED)) {
    return ReservationStatus.PAYMENT_REQUIRED;
  }
  if ([a, b].includes(ReservationStatus.RESERVED)) {
    return ReservationStatus.RESERVED;
  }
  if ([a, b].includes(ReservationStatus.COMPLETED)) {
    return ReservationStatus.COMPLETED;
  }
  if ([a, b].includes(ReservationStatus.CANCELLED)) {
    return ReservationStatus.CANCELLED;
  }
  if ([a, b].includes(ReservationStatus.FAILED)) {
    return ReservationStatus.FAILED;
  }
  return a ?? b;
}

function generateCompoundReservations(reservations: (Reservation | null)[]) {
  const ret: CompoundReservation[] = [];
  /* Populate all non-extend reservations */
  reservations.forEach((res) => {
    if (res && res.from && res.to && !res.prevReservationId) {
      ret.push({
        initialId: res.id,
        from: new Date(res.from),
        to: new Date(res.to),
        status: res.status!,
        spaceId: res.spaceId!,
        spaceInfo: res.space!,
        timeZone: res.building?.timezone,
        originals: [res],
      });
    }
  });

  /* Extend them */
  // Important to start with oldest first, so prevReservationId always exist
  reservations
    .sort((a, b) => Date.parse(a!.from!) - Date.parse(b!.from!))
    .forEach((res) => {
      if (res && res.from && res.to && res.prevReservationId) {
        const cmpd = ret.find((v) => v.originals.find((x) => x && x.id === res.prevReservationId));
        if (cmpd) {
          const newTo = new Date(res.to);
          if (isAfter(newTo, cmpd.to)) {
            cmpd.to = newTo;
          }
          cmpd.status = combineStatus(cmpd.status, res.status!);
          cmpd.originals.push(res);
        } else {
          // console.warn(`Reservation ${res.id}: Its previous reservation with id ${res.prevReservationId} not found`);
          ret.push({
            initialId: res.id,
            from: new Date(res.from),
            to: new Date(res.to),
            status: res.status!,
            spaceId: res.spaceId!,
            spaceInfo: res.space!,
            timeZone: res.building?.timezone,
            originals: [res],
          });
        }
      }
    });
  return ret;
}

export function useMyCompoundReservations() {
  const res = useGetMyPastReservationQuery();
  const values = useMemo(() => {
    return {
      isLoading: res.isLoading,
      reservations: generateCompoundReservations(res.data?.data?.getMyReservations ?? []),
    };
  }, [res.data?.data?.getMyReservations, res.isLoading]);
  return values;
}
const eligibleStatuses = [ReservationStatus.RESERVED, ReservationStatus.COMPLETED];

export function useExtractedReservationData() {
  const reservationData = useMyCompoundReservations();
  const now = useNowMinutes();

  const ret = useMemo(() => {
    const recentReservations = reservationData.reservations;
    const upcomingReservations = recentReservations.filter((reservation) => {
      return eligibleStatuses.includes(reservation!.status!) && isBefore(now, new Date(reservation!.to!));
    });

    const pastReservations = recentReservations.filter((reservation) => {
      return eligibleStatuses.includes(reservation!.status!) && !isAfter(new Date(reservation!.to!), now);
    });

    const ongoingReservations = recentReservations.filter((reservation) => {
      return (
        ReservationStatus.RESERVED === reservation?.status &&
        isWithinInterval(now, {
          start: new Date(reservation!.from!),
          end: new Date(reservation!.to!),
        })
      );
    });

    // Sort so the next one is first (i.e. smallest date first)
    upcomingReservations.sort((a, b) => new Date(a!.from!).getTime() - new Date(b!.from!).getTime());
    // Sort so the latest one is first (i.e. largest date first)
    pastReservations.sort((a, b) => new Date(b!.from!).getTime() - new Date(a!.from!).getTime());
    // Sort so the first one that started is first (i.e. smallest date first)
    ongoingReservations.sort((a, b) => new Date(a!.from!).getTime() - new Date(b!.from!).getTime());

    return {
      isLoading: reservationData.isLoading,
      upcomingReservations,
      pastReservations,
      ongoingReservations,
    };
  }, [now, reservationData.isLoading, reservationData.reservations]);

  return ret;
}

export function createReservation(input: MutationMakeAReservationArgs) {
  const mutation = /* GraphQL */ `
    mutation MakeAReservation($input: ReservationInput, $usedCredit: Int!) {
      makeAReservation(input: $input, usedCredit: $usedCredit) {
        id
        status
        credit
        currency
        paymentAmountInCents
        depositAmountInCents
        from
        to
        userId
        paymentId
        paymentSecret
        building {
          timezone
        }
        pois {
          id
          lat
          lon
          buildingRef
          localRef
          layerIndex
          title
          resources {
            id
            title
            spaceId
            resourceType
            resourceId
          }
          building {
            timezone
          }
        }
      }
    }
  `;
  return appsyncGraphql<'makeAReservation'>(mutation, input);
}

export function extendReservation(input: MutationExtendAReservationArgs) {
  const mutation = /* GraphQL */ `
    mutation ExtendAReservation($id: ID!, $to: AWSDateTime, $usedCredit: Int!) {
      extendAReservation(id: $id, to: $to, usedCredit: $usedCredit) {
        id
        status
        from
        to
        userId
        paymentId
        paymentSecret
        credit
        currency
        paymentAmountInCents
        depositAmountInCents
        prevReservationId
        building {
          timezone
        }
        pois {
          id
          lat
          lon
          buildingRef
          localRef
          layerIndex
          title
          resources {
            id
            title
            spaceId
            resourceType
            resourceId
          }
          building {
            timezone
          }
        }
      }
    }
  `;
  return appsyncGraphql<'extendAReservation'>(mutation, input);
}

export function endReservation(input: MutationEndAReservationArgs) {
  const mutation = /* GraphQL */ `
    mutation EndAReservation($id: ID!) {
      endAReservation(id: $id) {
        id
        status
        from
        to
        userId
        paymentId
        paymentSecret
        building {
          timezone
        }
        pois {
          id
          lat
          lon
          buildingRef
          localRef
          layerIndex
          title
          resources {
            id
            title
            spaceId
            resourceType
            resourceId
          }
          building {
            timezone
          }
        }
      }
    }
  `;
  return appsyncGraphql<'endAReservation'>(mutation, input);
}

export function cancelReservation(input: MutationCancelAReservationArgs) {
  const mutation = /* GraphQL */ `
    mutation CancelAReservation($id: ID!) {
      cancelAReservation(id: $id) {
        id
      }
    }
  `;
  return appsyncGraphql<'cancelAReservation'>(mutation, input);
}

interface SubscriptionGeneric<T, E = any> {
  value: {
    data: T;
    errors: E[];
  };
}

export function useSubscribeToReservationChange(
  rangeValues: SubscriptionOnUpdateReservationArgs,
  timeRange: TimeRangeInput,
) {
  const graphQl = /* GraphQL */ `
    subscription OnUpdateReservation($reservedDay: String) {
      onUpdateReservation(reservedDay: $reservedDay) {
        id
        status
        from
        to
        userId
        receiptUrl
        building {
          timezone
        }
        pois {
          id
          lat
          lon
          buildingRef
          localRef
          layerIndex
          title
          status
          building {
            timezone
          }
        }
      }
    }
  `;
  const queryClient = useQueryClient();
  const { isError } = useSubscription<SubscriptionGeneric<OnUpdateReservationSubscription>>(graphQl, rangeValues, {
    // Error might happen when the app returns from background.
    // We will try to re-subscribe at max 3 times
    maxRetry: MAX_RETRY,
    onSuccess: (value) => {
      if (
        !value.value.data.onUpdateReservation ||
        safeAreIntervalsOverlapping(
          {
            start: new Date(value.value.data.onUpdateReservation.from!),
            end: new Date(value.value.data.onUpdateReservation.to!),
          },
          {
            start: new Date(timeRange.from!),
            end: new Date(timeRange.to!),
          },
        )
      ) {
        queryClient.invalidateQueries(getBuildingsWithinRadius.id);
      }
    },
    onError: (error) => {
      // TODO: We are getting a ton of these.
      console.log('useSubscribeToReservationChange', JSON.stringify(error?.error?.errors ?? {}));
    },
  });

  return { isError };
}

export function useSubscribeToSinglePodReservationChange(
  rangeValues: SubscriptionOnUpdateReservationArgs,
  timeRange: TimeRangeInput,
) {
  const graphQl = /* GraphQL */ `
    subscription OnUpdateReservation($poisId: ID, $reservedDay: String) {
      onUpdateReservation(poisId: $poisId, reservedDay: $reservedDay) {
        id
        status
        from
        to
        userId
        receiptUrl
        building {
          timezone
        }
        pois {
          id
          lat
          lon
          buildingRef
          localRef
          layerIndex
          title
          status
          building {
            timezone
          }
        }
      }
    }
  `;

  const queryClient = useQueryClient();
  const { isError } = useSubscription<SubscriptionGeneric<Pick<Subscription, 'onUpdateReservation'>>>(
    graphQl,
    rangeValues,
    {
      // Error might happen when the app returns from background.
      // We will try to re-subscribe at max 3 times
      maxRetry: MAX_RETRY,
      onSuccess: (value) => {
        if (
          !value.value.data.onUpdateReservation ||
          safeAreIntervalsOverlapping(
            {
              start: new Date(value.value.data.onUpdateReservation.from!),
              end: new Date(value.value.data.onUpdateReservation.to!),
            },
            {
              start: new Date(timeRange.from!),
              end: new Date(timeRange.to!),
            },
          )
        ) {
          queryClient.invalidateQueries(useGetSpaceScheduleInfo.id);
        }
      },
      onError: (error) => {
        // TODO: We are getting a ton of these.
        console.log('useSubscribeToSinglePodReservationChange', JSON.stringify(error?.error?.errors ?? {}));
      },
    },
  );

  return {
    isError,
  };
}

// We should only have one of these, ever
export function useSubscribeToOwnReservationChange(
  rangeValues: SubscriptionOnUpdateReservationArgs,
  timeRange: TimeRangeInput,
) {
  const fetchMyProfileQuery = useMyProfileQuery();
  const graphQl = /* GraphQL */ `
    subscription OnUpdateReservation($userId: ID) {
      onUpdateReservation(userId: $userId) {
        id
        status
        from
        to
        userId
        receiptUrl
        building {
          timezone
        }
        pois {
          id
          lat
          lon
          buildingRef
          localRef
          layerIndex
          title
          status
          building {
            timezone
          }
        }
      }
    }
  `;
  const queryClient = useQueryClient();
  const { isError } = useSubscription<SubscriptionGeneric<OnUpdateReservationSubscription>>(
    graphQl,
    { rangeValues, userId: fetchMyProfileQuery?.data?.data?.getMyProfile?.sub },
    {
      // Error might happen when the app returns from background.
      // We will try to re-subscribe at max 3 times
      maxRetry: MAX_RETRY,
      onSuccess: (value) => {
        if (
          !value.value.data.onUpdateReservation ||
          safeAreIntervalsOverlapping(
            {
              start: new Date(value.value.data.onUpdateReservation.from!),
              end: new Date(value.value.data.onUpdateReservation.to!),
            },
            {
              start: new Date(timeRange.from!),
              end: new Date(timeRange.to!),
            },
          )
        ) {
          queryClient.invalidateQueries(getMyReservations.id);
        }
      },
      onError: (error) => {
        // TODO: We are getting a ton of these.
        console.log('useSubscribeToOwnReservationChange', JSON.stringify(error?.error?.errors ?? {}));
      },
    },
  );

  return {
    isError,
  };
}

export function useSubscribeToBuildingAvailability(
  rangeValues: SubscriptionOnUpdateBuildingBookingStatusArgs,
  timeRange: TimeRangeInput,
) {
  const graphQl = /* GraphQL */ `
    subscription OnUpdateBuildingBookingStatus {
      onUpdateBuildingBookingStatus {
        buildingRef
        from
        to
        bookable
        total
      }
    }
  `;
  const queryClient = useQueryClient();
  const { isError } = useSubscription<SubscriptionGeneric<OnUpdateBuildingBookingStatusSubscription>>(
    graphQl,
    {},
    {
      // Error might happen when the app returns from background.
      // We will try to re-subscribe at max 3 times
      maxRetry: MAX_RETRY,
      trigger: rangeValues,
      onSuccess: (value) => {
        if (
          !value.value.data.onUpdateBuildingBookingStatus ||
          safeAreIntervalsOverlapping(
            {
              start: new Date(value.value.data.onUpdateBuildingBookingStatus.from!),
              end: new Date(value.value.data.onUpdateBuildingBookingStatus.to!),
            },
            {
              start: new Date(timeRange.from!),
              end: new Date(timeRange.to!),
            },
          )
        ) {
          queryClient.invalidateQueries(getBuildingsWithinRadius.id);
        }
      },
      onError: (error) => {
        console.warn(error);
        Sentry.captureException(
          new Error('Subscribing to building booking status gave error: ' + safeJsonStringify(error)),
        );
      },
    },
  );

  return {
    isError,
  };
}

export function invalidateReservationData(queryClient: QueryClient) {
  queryClient.invalidateQueries(getMyReservations.id);
  queryClient.invalidateQueries(useGetSpaceScheduleInfo.id);
  queryClient.invalidateQueries(useGetSpaceInfoWithPricing.id);
  queryClient.invalidateQueries(useGetRecentCreditBalanceHistory.id);
  queryClient.invalidateQueries(useMyProfileQuery.id);
  queryClient.invalidateQueries(getBuildingsWithinRadius.id);
}
