import React from 'react';
import { Dialog, Surface } from 'react-native-paper';
import { FlatList, GestureResponderEvent, View } from 'react-native';
import { useI18n } from '../../context/I18nContext';
import SummaryHeader from '../SummaryHeader';
import { ModalButtonWrapper } from './TextModal';
import { Resource } from '../../types/appsync-types';
import { ResourceLockButton } from '../ResourceLockButton';
import { ResourceSignalStrength } from '../../types/misc.types';

type SelectResourceModalProps = {
  resources: (Resource | null | undefined)[];
  visibleResources: { id: string; signalStrength?: ResourceSignalStrength }[];
  disabled?: boolean;
  loading: boolean;
  onPress?: (e: GestureResponderEvent) => void;
  onDismiss: () => void;
  accessResource: (resourceId: string) => Promise<unknown>;
};

export function SelectResourceModal({
  resources,
  visibleResources,
  disabled,
  loading,
  onPress,
  onDismiss,
  accessResource,
}: SelectResourceModalProps) {
  const { I18n } = useI18n();

  const handlePress = (e: GestureResponderEvent) => {
    onPress?.(e);
    onDismiss();
  };

  return (
    <Surface>
      <View style={{ overflow: 'hidden' }}>
        <SummaryHeader title={I18n.t('selectResourceModal.title')} style={{ paddingHorizontal: 16 }} />
        <View>
          <FlatList
            data={resources}
            scrollEnabled={true}
            style={{ maxHeight: 250 }}
            renderItem={({ item }) => {
              const signalStrength = visibleResources.find(
                (resource) => resource.id === item?.resourceId,
              )?.signalStrength;
              return (
                <ResourceLockButton
                  resourceId={item?.resourceId}
                  signalStrength={signalStrength}
                  loading={loading}
                  accessResource={accessResource}
                  onPress={handlePress}
                  style={{ paddingHorizontal: 16, marginVertical: 8 }}
                  disabled={disabled}
                >
                  {item?.title}
                </ResourceLockButton>
              );
            }}
          />
        </View>
        <Dialog.Actions>
          <ModalButtonWrapper key={'cancel'} title={I18n.t('general.cancel')} onPress={onDismiss} />
        </Dialog.Actions>
      </View>
    </Surface>
  );
}
