// WARNING: DO NOT EDIT.  This file is automatically generated
// Written by aws-amplify-serverless-plugin/1.4.1 on 2021-02-17T13:31:57.882Z

interface IAWSAmplifyFederatedConfiguration {
  google_client_id?: string;
  facebook_app_id?: string;
  amazon_client_id?: string;
}

interface IAWSAmplifyCloudLogicConfiguration {
  [index: number]: {
    endpoint: string;
    name: string;
    region: string;
  };
}

interface IAWSAmplifyConfiguration {
  aws_appsync_authenticationType?: string;
  aws_appsync_graphqlEndpoint?: string;
  aws_appsync_region?: string;
  aws_cognito_identity_pool_id?: string;
  aws_cognito_region?: string;
  aws_cloud_logic_custom?: IAWSAmplifyCloudLogicConfiguration;
  aws_project_region: string;
  aws_user_files_s3_bucket?: string;
  aws_user_files_s3_bucket_region?: string;
  aws_user_pools_id?: string;
  aws_user_pools_web_client_id?: string;
  aws_user_pools_web_client_secret?: string;
  aws_mobile_analytics_app_id?: string;
  federated?: IAWSAmplifyFederatedConfiguration;
  aws_rest_api_url?: string;
}

const awsmobile: IAWSAmplifyConfiguration = {
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_graphqlEndpoint: 'https://appsync.app.dev.biketti.tech/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_cognito_identity_pool_id: 'eu-west-1:083be8e5-5013-4745-a676-b0505ed3aac8',
  aws_cognito_region: 'eu-west-1',
  aws_project_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_oYBic1AjK',
  aws_user_pools_web_client_id: '2ifu7ob3d4i0omh343vq1i8km1',
  aws_mobile_analytics_app_id: '7cf1a5dc767244e8ae5810d9bcf25364',
  aws_rest_api_url: 'https://rest.app.dev.biketti.tech',
};

export default awsmobile;
