export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The AWSDate scalar type represents a valid extended ISO 8601 Date string. In other words, this scalar type accepts date strings of the form YYYY-MM-DD. This scalar type can also accept time zone offsets. For example, 1970-01-01Z, 1970-01-01-07:00 and 1970-01-01+05:30 are all valid dates. The time zone offset must either be Z (representing the UTC time zone) or be in the format ±hh:mm:ss. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDate: string;
  /** The AWSDateTime scalar type represents a valid extended ISO 8601 DateTime string. In other words, this scalar type accepts datetime strings of the form YYYY-MM-DDThh:mm:ss.sssZ. The field after the seconds field is a nanoseconds field. It can accept between 1 and 9 digits. The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used). The time zone offset is compulsory for this scalar. The time zone offset must either be Z (representing the UTC time zone) or be in the format ±hh:mm:ss. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: string;
  /** The AWSEmail scalar type represents an Email address string that complies with RFC 822. For example, username@example.com is a valid Email address. */
  AWSEmail: string;
  /** The AWSIPAddress scalar type represents a valid IPv4 or IPv6 address string. */
  AWSIPAddress: string;
  /**
   * The AWSJSON scalar type represents a JSON string that complies with RFC 8259.
   *
   * Maps like {\"upvotes\": 10}, lists like [1,2,3], and scalar values like \"AWSJSON example string\", 1, and true are accepted as valid JSON. They will automatically be parsed and loaded in the resolver mapping templates as Maps, Lists, or Scalar values rather than as the literal input strings. Invalid JSON strings like {a: 1}, {'a': 1} and Unquoted string will throw GraphQL validation errors.
   */
  AWSJSON: string;
  /** The AWSPhone scalar type represents a valid Phone Number. Phone numbers are serialized and deserialized as Strings. Phone numbers provided may be whitespace delimited or hyphenated. The number can specify a country code at the beginning but this is not required. */
  AWSPhone: string;
  /**
   * The AWSTime scalar type represents a valid extended ISO 8601 Time string. In other words, this scalar type accepts time strings of the form hh:mm:ss.sss. The field after the seconds field is a nanoseconds field. It can accept between 1 and 9 digits. The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used). This scalar type can also accept time zone offsets.
   *
   * For example, 12:30Z, 12:30:24-07:00 and 12:30:24.500+05:30 are all valid time strings.
   *
   * The time zone offset must either be Z (representing the UTC time zone) or be in the format hh:mm:ss. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard.
   */
  AWSTime: string;
  /** The AWSTimestamp scalar type represents the number of seconds that have elapsed since 1970-01-01T00:00Z. Timestamps are serialized and deserialized as numbers. Negative values are also accepted and these represent the number of seconds till 1970-01-01T00:00Z. */
  AWSTimestamp: number;
  /** The AWSURL scalar type represents a valid URL string. The URL may use any scheme and may also be a local URL (Ex: <http://localhost/>). URLs without schemes are considered invalid. URLs which contain double slashes are also considered invalid. */
  AWSURL: string;
};

export type Building = {
  __typename?: 'Building';
  address?: Maybe<Scalars['String']>;
  bookingStatus?: Maybe<BuildingBookingStatus>;
  buildingRef?: Maybe<Scalars['String']>;
  buildingStatus?: Maybe<BuildingBookingStatus>;
  currency?: Maybe<Currency>;
  hide?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  pois?: Maybe<Array<Maybe<Pois>>>;
  spaces?: Maybe<Array<Maybe<Pois>>>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

export type BuildingBookingStatusArgs = {
  input?: InputMaybe<TimeRangeInput>;
};

export type BuildingBuildingStatusArgs = {
  input?: InputMaybe<TimeRangeInput>;
};

export type BuildingBookingStatus = {
  __typename?: 'BuildingBookingStatus';
  bookable?: Maybe<Scalars['Int']>;
  buildingRef?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['AWSDateTime']>;
  to?: Maybe<Scalars['AWSDateTime']>;
  total?: Maybe<Scalars['Int']>;
};

export type BuildingBookingStatusQueryInput = {
  buildingRef: Scalars['ID'];
  from?: InputMaybe<Scalars['AWSDateTime']>;
  to?: InputMaybe<Scalars['AWSDateTime']>;
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['ID'];
  name: Scalars['String'];
  regions?: Maybe<Array<Maybe<Region>>>;
  translationId?: Maybe<Scalars['String']>;
};

export type CreditHistory = {
  __typename?: 'CreditHistory';
  balanceAfter?: Maybe<Scalars['Float']>;
  change?: Maybe<Scalars['Float']>;
  creditType?: Maybe<CreditType>;
  currency?: Maybe<Currency>;
  internalNote?: Maybe<Scalars['String']>;
  sourceCode?: Maybe<Scalars['String']>;
  sourceName?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactionTime?: Maybe<Scalars['AWSDateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export enum CreditType {
  BONUS = 'BONUS',
  FRIEND_INVITE_CODE = 'FRIEND_INVITE_CODE',
  KICKBACK = 'KICKBACK',
  PROMO_INVITE_CODE = 'PROMO_INVITE_CODE',
  REFUND = 'REFUND',
  RESERVATION = 'RESERVATION',
  RESERVATION_EXTEND = 'RESERVATION_EXTEND',
  VOUCHER_CODE = 'VOUCHER_CODE',
}

export enum Currency {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHF = 'CHF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GGP = 'GGP',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  IMP = 'IMP',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JEP = 'JEP',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRU = 'MRU',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SPL = 'SPL',
  SRD = 'SRD',
  STN = 'STN',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TVD = 'TVD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  UYU = 'UYU',
  UZS = 'UZS',
  VEF = 'VEF',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XCD = 'XCD',
  XDR = 'XDR',
  XOF = 'XOF',
  XPF = 'XPF',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMW = 'ZMW',
  ZWD = 'ZWD',
}

export type GetMyInvitedReservationsInput = {
  accessLevel?: InputMaybe<ReservationAccessLevelForMembership>;
  exclusiveStartKey?: InputMaybe<MembershipEvaluateKeyInput>;
  from: Scalars['AWSDateTime'];
  to: Scalars['AWSDateTime'];
};

export type GetReservationsWithPaginationInput = {
  exclusiveStartKey?: InputMaybe<ReservationEvaluateKeyInput>;
  from?: InputMaybe<Scalars['AWSDateTime']>;
  to?: InputMaybe<Scalars['AWSDateTime']>;
};

export type Membership = {
  __typename?: 'Membership';
  accessLevel: ReservationAccessLevelForMembership;
  from?: Maybe<Scalars['AWSDateTime']>;
  phonenumber: Scalars['AWSPhone'];
  reservationId: Scalars['ID'];
  to?: Maybe<Scalars['AWSDateTime']>;
};

export type MembershipEvaluateKeyInput = {
  from: Scalars['AWSDateTime'];
  phonenumber: Scalars['AWSPhone'];
  reservationId: Scalars['ID'];
};

export type MembershipEvaluateKeyOutput = {
  __typename?: 'MembershipEvaluateKeyOutput';
  from: Scalars['AWSDateTime'];
  phonenumber: Scalars['AWSPhone'];
  reservation: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addProfile?: Maybe<Profile>;
  addStripePayment?: Maybe<Scalars['String']>;
  applyVoucherCode?: Maybe<Profile>;
  cancelAReservation?: Maybe<Reservation>;
  endAReservation?: Maybe<Reservation>;
  extendAReservation?: Maybe<Reservation>;
  makeAReservation?: Maybe<Reservation>;
  removeAPaymentMethod?: Maybe<Scalars['String']>;
  setDefaultPayment?: Maybe<Scalars['String']>;
  shareReservationAccess?: Maybe<Array<Membership>>;
  signup?: Maybe<SignupResponse>;
  unshareReservationAccess?: Maybe<Membership>;
  updateBuildingBookingStatus?: Maybe<BuildingBookingStatus>;
  updateMyNotificationPreference?: Maybe<NotificationPreference>;
  updateProfile?: Maybe<Profile>;
  updateProfilePinpoint?: Maybe<Profile>;
  updateReservation?: Maybe<Reservation>;
};

export type MutationAddProfileArgs = {
  country?: InputMaybe<SupportCountry>;
  firstname?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<SupportLanguage>;
  lastname?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  phonenumber?: InputMaybe<Scalars['AWSPhone']>;
};

export type MutationAddStripePaymentArgs = {
  default?: InputMaybe<Scalars['Boolean']>;
};

export type MutationApplyVoucherCodeArgs = {
  code: Scalars['String'];
};

export type MutationCancelAReservationArgs = {
  id: Scalars['ID'];
};

export type MutationEndAReservationArgs = {
  id: Scalars['ID'];
};

export type MutationExtendAReservationArgs = {
  id: Scalars['ID'];
  to?: InputMaybe<Scalars['AWSDateTime']>;
  usedCredit: Scalars['Int'];
};

export type MutationMakeAReservationArgs = {
  input?: InputMaybe<ReservationInput>;
  usedCredit: Scalars['Int'];
};

export type MutationRemoveAPaymentMethodArgs = {
  card_id: Scalars['ID'];
};

export type MutationSetDefaultPaymentArgs = {
  card_id: Scalars['ID'];
};

export type MutationShareReservationAccessArgs = {
  input: ShareReservationAccessInput;
};

export type MutationSignupArgs = {
  code?: InputMaybe<Scalars['String']>;
  phonenumber: Scalars['AWSPhone'];
};

export type MutationUnshareReservationAccessArgs = {
  input: UnshareReservationAccessInput;
};

export type MutationUpdateBuildingBookingStatusArgs = {
  input?: InputMaybe<BuildingBookingStatusQueryInput>;
};

export type MutationUpdateMyNotificationPreferenceArgs = {
  input?: InputMaybe<NotificationPreferenceInput>;
};

export type MutationUpdateProfileArgs = {
  country?: InputMaybe<SupportCountry>;
  firstname?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<SupportLanguage>;
  lastname?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  phonenumber?: InputMaybe<Scalars['AWSPhone']>;
};

export type MutationUpdateReservationArgs = {
  id: Scalars['ID'];
};

export type NotificationPreference = {
  __typename?: 'NotificationPreference';
  beforeEndingTenMinutes?: Maybe<Scalars['Boolean']>;
  beforeStaringOneDay?: Maybe<Scalars['Boolean']>;
  beforeStartingTenMinutes?: Maybe<Scalars['Boolean']>;
  completed?: Maybe<Scalars['Boolean']>;
  news?: Maybe<Scalars['Boolean']>;
  startingNow?: Maybe<Scalars['Boolean']>;
};

export type NotificationPreferenceInput = {
  beforeEndingTenMinutes?: InputMaybe<Scalars['Boolean']>;
  beforeStaringOneDay?: InputMaybe<Scalars['Boolean']>;
  beforeStartingTenMinutes?: InputMaybe<Scalars['Boolean']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  news?: InputMaybe<Scalars['Boolean']>;
  startingNow?: InputMaybe<Scalars['Boolean']>;
};

export type OpeningHour = {
  __typename?: 'OpeningHour';
  from?: Maybe<Scalars['AWSDateTime']>;
  open?: Maybe<Scalars['Boolean']>;
  to?: Maybe<Scalars['AWSDateTime']>;
};

export type PhoneNumberWithAccessLevel = {
  __typename?: 'PhoneNumberWithAccessLevel';
  accessLevel: ReservationAccessLevel;
  phonenumber: Scalars['AWSPhone'];
};

export type PodBookingStatus = {
  __typename?: 'PodBookingStatus';
  nextFreeEnd?: Maybe<Scalars['AWSDateTime']>;
  nextFreeStart?: Maybe<Scalars['AWSDateTime']>;
  status: PodStatus;
};

export enum PodStatus {
  FREE = 'FREE',
  RESERVED = 'RESERVED',
  RESERVED_BY_ME = 'RESERVED_BY_ME',
  RESERVED_BY_OTHER = 'RESERVED_BY_OTHER',
  UNAVAILABLE = 'UNAVAILABLE',
  UPCOMING_RESERVED = 'UPCOMING_RESERVED',
}

export enum ResourceType {
  BITWARD = 'BITWARD',
}

export type Resource = {
  __typename?: 'Resource';
  id: Scalars['ID'];
  resourceType?: Maybe<ResourceType>;
  title?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['String']>;
  space?: Maybe<Pois>;
  spaceId?: Maybe<Scalars['Int']>;
  hide?: Maybe<Scalars['Boolean']>;
};

export type ChargeRate = {
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  interval?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

export enum BillingType {
  PREPAID = 'PREPAID',
  POST_PAYMENT = 'POST PAYMENT',
}

export type Pois = {
  __typename?: 'Pois';
  availabilityStatus?: Maybe<PodBookingStatus>;
  building?: Maybe<Building>;
  buildingRef?: Maybe<Scalars['String']>;
  chargeRates?: Maybe<Array<ChargeRate>>;
  currency?: Maybe<Currency>;
  billingType?: Maybe<BillingType>;
  depositAmountInCents?: Maybe<Scalars['Int']>;
  hide?: Maybe<Scalars['Boolean']>;
  holdAmount?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  lat?: Maybe<Scalars['Float']>;
  layerIndex?: Maybe<Scalars['String']>;
  localRef?: Maybe<Scalars['String']>;
  lon?: Maybe<Scalars['Float']>;
  metadata?: Maybe<SpaceMetadata>;
  minimumBookingMinutes?: Maybe<Scalars['Int']>;
  openingHour?: Maybe<OpeningHour>;
  qrcode?: Maybe<Scalars['String']>;
  reservations?: Maybe<Array<Maybe<Reservation>>>;
  resources?: Maybe<Array<Maybe<Resource>>>;
  status?: Maybe<PodStatus>;
  taxRate?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type PoisAvailabilityStatusArgs = {
  from?: InputMaybe<Scalars['AWSDateTime']>;
};

export type PoisOpeningHourArgs = {
  at?: InputMaybe<Scalars['AWSDateTime']>;
};

export type PoisReservationsArgs = {
  input?: InputMaybe<TimeRangeInput>;
};

export type PoisStatusArgs = {
  input?: InputMaybe<TimeRangeInput>;
};

export type PriceInfo = {
  __typename?: 'PriceInfo';
  currency?: Maybe<Scalars['String']>;
  holdAmount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  vatAmount?: Maybe<Scalars['Float']>;
};

export type Profile = {
  __typename?: 'Profile';
  country?: Maybe<SupportCountry>;
  createdAt?: Maybe<Scalars['Float']>;
  createdat?: Maybe<Scalars['Float']>;
  creditBalance?: Maybe<Scalars['Float']>;
  currency?: Maybe<Currency>;
  firstname?: Maybe<Scalars['String']>;
  inviteCode?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  phonenumber?: Maybe<Scalars['AWSPhone']>;
  registerInviteCode?: Maybe<Scalars['String']>;
  skipDepositAmountInCents?: Maybe<Scalars['Int']>;
  sub: Scalars['ID'];
  updatedAt?: Maybe<Scalars['Float']>;
  updatedat?: Maybe<Scalars['Float']>;
  userRole?: Maybe<UserRole>;
};

export type Query = {
  __typename?: 'Query';
  estimatePriceAndHoldAmount?: Maybe<PriceInfo>;
  getAllPhoneNumbersFromReservation?: Maybe<Array<Maybe<PhoneNumberWithAccessLevel>>>;
  getBuilding?: Maybe<Array<Maybe<Building>>>;
  getBuildingById?: Maybe<Building>;
  getBuildingsByRegionId?: Maybe<Array<Maybe<Building>>>;
  getBuildingsWithinRadius?: Maybe<Array<Maybe<Building>>>;
  getCountries?: Maybe<Array<Maybe<Country>>>;
  getMyInvitedReservations?: Maybe<ReservationsWithAccessLevelWithPagination>;
  getMyNotificationPreference?: Maybe<NotificationPreference>;
  getMyProfile?: Maybe<Profile>;
  getMyRecentCreditHistory?: Maybe<Array<Maybe<CreditHistory>>>;
  getMyReservations?: Maybe<Array<Maybe<Reservation>>>;
  getMyReservationsWithPagination?: Maybe<ReservationsWithPagination>;
  getPois?: Maybe<Array<Maybe<Pois>>>;
  getPoisByCode?: Maybe<Pois>;
  getPoisByID?: Maybe<Pois>;
  getProfileById?: Maybe<Profile>;
  getRecentTransactions?: Maybe<Array<Maybe<Transaction>>>;
  getReservation?: Maybe<Reservation>;
  getReservationsOfPois?: Maybe<Array<Maybe<Reservation>>>;
  getSpaceReservations?: Maybe<Array<Maybe<Reservation>>>;
  getSpacesWithinRadius?: Maybe<Array<Maybe<Pois>>>;
  getStripePayment?: Maybe<Array<Maybe<StripeCard>>>;
  getVoucherByCode?: Maybe<Voucher>;
  podNextAvailability?: Maybe<Scalars['AWSDateTime']>;
};

export type QueryEstimatePriceAndHoldAmountArgs = {
  input?: InputMaybe<SpaceReservationInput>;
};

export type QueryGetAllPhoneNumbersFromReservationArgs = {
  reservationId: Scalars['ID'];
};

export type QueryGetBuildingArgs = {
  lat?: InputMaybe<Scalars['Float']>;
  lon?: InputMaybe<Scalars['Float']>;
  radius?: InputMaybe<Scalars['Float']>;
};

export type QueryGetBuildingByIdArgs = {
  id: Scalars['String'];
};

export type QueryGetBuildingsByRegionIdArgs = {
  id: Scalars['String'];
};

export type QueryGetBuildingsWithinRadiusArgs = {
  lat?: InputMaybe<Scalars['Float']>;
  lon?: InputMaybe<Scalars['Float']>;
  radius?: InputMaybe<Scalars['Float']>;
};

export type QueryGetMyInvitedReservationsArgs = {
  input: GetMyInvitedReservationsInput;
};

export type QueryGetMyReservationsArgs = {
  input?: InputMaybe<TimeRangeInput>;
};

export type QueryGetMyReservationsWithPaginationArgs = {
  input?: InputMaybe<GetReservationsWithPaginationInput>;
};

export type QueryGetPoisArgs = {
  lat?: InputMaybe<Scalars['Float']>;
  lon?: InputMaybe<Scalars['Float']>;
  radius?: InputMaybe<Scalars['Float']>;
};

export type QueryGetPoisByCodeArgs = {
  code: Scalars['String'];
};

export type QueryGetPoisByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetProfileByIdArgs = {
  sub: Scalars['ID'];
};

export type QueryGetReservationArgs = {
  id: Scalars['ID'];
};

export type QueryGetReservationsOfPoisArgs = {
  input?: InputMaybe<ReservationInput>;
};

export type QueryGetSpaceReservationsArgs = {
  input?: InputMaybe<SpaceReservationInput>;
};

export type QueryGetSpacesWithinRadiusArgs = {
  lat?: InputMaybe<Scalars['Float']>;
  lon?: InputMaybe<Scalars['Float']>;
  radius?: InputMaybe<Scalars['Float']>;
};

export type QueryGetVoucherByCodeArgs = {
  code: Scalars['String'];
};

export type QueryPodNextAvailabilityArgs = {
  durationInMinute?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
};

export type Region = {
  __typename?: 'Region';
  buildings?: Maybe<Array<Maybe<Building>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  translationId: Scalars['String'];
};

export type Reservation = {
  __typename?: 'Reservation';
  building?: Maybe<Building>;
  buildingRef?: Maybe<Scalars['ID']>;
  card?: Maybe<StripeCard>;
  credit?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  depositAmountInCents?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  nextReservationId?: Maybe<Scalars['String']>;
  paymentAmountInCents?: Maybe<Scalars['Int']>;
  paymentId?: Maybe<Scalars['String']>;
  paymentSecret?: Maybe<Scalars['String']>;
  pois?: Maybe<Pois>;
  poisId: Scalars['ID'];
  prevReservationId?: Maybe<Scalars['String']>;
  receiptUrl?: Maybe<Scalars['String']>;
  requiresDeposit?: Maybe<Scalars['Boolean']>;
  reservedDay?: Maybe<Scalars['String']>;
  space?: Maybe<Pois>;
  spaceId?: Maybe<Scalars['String']>;
  status?: Maybe<ReservationStatus>;
  to?: Maybe<Scalars['AWSDateTime']>;
  type?: Maybe<ReservationType>;
  userId?: Maybe<Scalars['String']>;
};

export enum ReservationAccessLevel {
  CREATOR = 'CREATOR',
  GUEST_WITHOUT_ACCESS = 'GUEST_WITHOUT_ACCESS',
  GUEST_WITH_ACCESS = 'GUEST_WITH_ACCESS',
}

export enum ReservationAccessLevelForMembership {
  GUEST_WITHOUT_ACCESS = 'GUEST_WITHOUT_ACCESS',
  GUEST_WITH_ACCESS = 'GUEST_WITH_ACCESS',
}

export type ReservationEvaluateKeyInput = {
  from: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  userId: Scalars['ID'];
};

export type ReservationEvaluateKeyOutput = {
  __typename?: 'ReservationEvaluateKeyOutput';
  from: Scalars['AWSDateTime'];
  id: Scalars['String'];
  userId: Scalars['String'];
};

export type ReservationInput = {
  from?: InputMaybe<Scalars['AWSDateTime']>;
  poisId: Scalars['ID'];
  to?: InputMaybe<Scalars['AWSDateTime']>;
};

export enum ReservationStatus {
  CANCELLED = 'CANCELLED',
  CAPTURED_REQUIRED = 'CAPTURED_REQUIRED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PAYMENT_REQUIRED = 'PAYMENT_REQUIRED',
  REFUND_REQUIRED = 'REFUND_REQUIRED',
  RESERVED = 'RESERVED',
}

export enum ReservationType {
  EXTENDED = 'EXTENDED',
  NEW = 'NEW',
}

export type ReservationWithAccessLevel = {
  __typename?: 'ReservationWithAccessLevel';
  accessLevel: ReservationAccessLevelForMembership;
  reservation: Reservation;
};

export type ReservationsWithAccessLevelWithPagination = {
  __typename?: 'ReservationsWithAccessLevelWithPagination';
  items?: Maybe<Array<Maybe<ReservationWithAccessLevel>>>;
  lastEvaluateKey?: Maybe<MembershipEvaluateKeyOutput>;
};

export type ReservationsWithPagination = {
  __typename?: 'ReservationsWithPagination';
  items?: Maybe<Array<Maybe<Reservation>>>;
  lastEvaluateKey?: Maybe<ReservationEvaluateKeyOutput>;
};

export type ShareReservationAccessInput = {
  accessLevel: ReservationAccessLevelForMembership;
  phonenumbers: Array<Scalars['AWSPhone']>;
  reservationId: Scalars['ID'];
};

export enum SignupResponse {
  ERR_BAD_REQUEST = 'ERR_BAD_REQUEST',
  ERR_CODE_INVALID = 'ERR_CODE_INVALID',
  ERR_CODE_NOT_EXISTED = 'ERR_CODE_NOT_EXISTED',
  ERR_NOT_FIRST_TIME = 'ERR_NOT_FIRST_TIME',
  SUCCESS = 'SUCCESS',
}

export type SpaceMetadata = {
  __typename?: 'SpaceMetadata';
  imageUrl?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  maxCapacity?: Maybe<Scalars['Int']>;
  powerOutlets?: Maybe<Scalars['String']>;
  screen?: Maybe<Scalars['String']>;
  seating?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  usbAPower?: Maybe<Scalars['String']>;
  usbCPower?: Maybe<Scalars['String']>;
  whiteboard?: Maybe<Scalars['String']>;
};

export type SpaceReservationInput = {
  from: Scalars['AWSDateTime'];
  spaceId: Scalars['String'];
  to: Scalars['AWSDateTime'];
};

export type StripeCard = {
  __typename?: 'StripeCard';
  brand?: Maybe<Scalars['String']>;
  cvc_check?: Maybe<Scalars['String']>;
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  is_default?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  onUpdateBuildingBookingStatus?: Maybe<BuildingBookingStatus>;
  onUpdateReservation?: Maybe<Reservation>;
};

export type SubscriptionOnUpdateBuildingBookingStatusArgs = {
  buildingRef?: InputMaybe<Scalars['ID']>;
  reservedDay?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnUpdateReservationArgs = {
  poisId?: InputMaybe<Scalars['ID']>;
  reservedDay?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export enum SupportCountry {
  FIN = 'FIN',
  SWE = 'SWE',
}

export enum SupportLanguage {
  EN = 'en',
  FI = 'fi',
  SV = 'sv',
}

export type TimeRangeInput = {
  from?: InputMaybe<Scalars['AWSDateTime']>;
  to?: InputMaybe<Scalars['AWSDateTime']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  amount?: Maybe<Scalars['Float']>;
  card?: Maybe<StripeCard>;
  currency?: Maybe<Currency>;
  reservation?: Maybe<Reservation>;
};

export type UnshareReservationAccessInput = {
  phonenumber: Scalars['AWSPhone'];
  reservationId: Scalars['ID'];
};

export enum UserRole {
  SUPER_ADMIN = 'SUPER_ADMIN',
  USER = 'USER',
}

export type Voucher = {
  __typename?: 'Voucher';
  creationDate: Scalars['AWSDateTime'];
  currency: Currency;
  enabled: Scalars['Boolean'];
  expiryDate?: Maybe<Scalars['AWSDateTime']>;
  sourceName: Scalars['String'];
  used: Scalars['Boolean'];
  value: Scalars['Int'];
  voucherCode: Scalars['String'];
  voucherNumber: Scalars['Int'];
};
